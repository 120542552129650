import React, { useState } from "react";
import "./Bmi.css";

function BmiCalculator() {
  const [heightValue, setHeightValue] = useState("");
  const [weightValue, setWeightValue] = useState("");
  const [heightUnit, setHeightUnit] = useState("cm");
  const [weightUnit, setWeightUnit] = useState("kg");
  const [bmiValue, setBmiValue] = useState("");
  const [bmiMessage, setBmiMessage] = useState("");

  const calculateBmi = () => {
    if (heightValue && weightValue) {
      let weight = parseFloat(weightValue);
      let height = parseFloat(heightValue);

      // Convert units
      if (weightUnit === "lbs") {
        weight *= 0.453592; // lbs to kg
      }
      if (heightUnit === "ft") {
        height *= 30.48; // feet to cm
      }

      const heightInMeters = height / 100;
      const bmi = (weight / (heightInMeters * heightInMeters)).toFixed(2);
      setBmiValue(bmi);

      let message = "";
      if (bmi < 18.5) {
        message = "You are Underweight";
      } else if (bmi >= 18.5 && bmi < 25) {
        message = "You are Normal weight";
      } else if (bmi >= 25 && bmi < 30) {
        message = "You are Overweight";
      } else {
        message = "You are Obese";
      }
      setBmiMessage(message);
    } else {
      setBmiValue("");
      setBmiMessage("");
    }
  };

  return (
    <div className="bmi-container container">
      <h1>Balm BMI Calculator</h1>
      
      {/* Weight Unit Selection */}
      <div className="select-container">
        <label htmlFor="weight-unit">Weight Unit:</label>
        <select className="bmi-select" id="weight-unit" value={weightUnit} onChange={(e) => setWeightUnit(e.target.value)}>
          <option value="kg">kg</option>
          <option value="lbs">lbs</option>
        </select>
      </div>

      <input
        type="number"
        id="weight"
        value={weightValue}
        onChange={(e) => setWeightValue(e.target.value)}
        placeholder="Enter your weight"
      />

      {/* Height Unit Selection */}
      <div className="select-container">
        <label htmlFor="height-unit">Height Unit:</label>
        <select className="bmi-select" id="height-unit" value={heightUnit} onChange={(e) => setHeightUnit(e.target.value)}>
          <option value="cm">cm</option>
          <option value="ft">ft</option>
        </select>
      </div>

      <input
        type="number"
        id="height"
        value={heightValue}
        onChange={(e) => setHeightValue(e.target.value)}
        placeholder="Enter your height"
      />

      <button onClick={calculateBmi}>Calculate BMI</button>

      {/* BMI Result */}
      {bmiValue && bmiMessage && (
        <div className="result">
          <p>
            Your BMI: <strong>{bmiValue}</strong>
          </p>
          <p>Result: <strong>{bmiMessage}</strong></p>
        </div>
      )}

      {/* BMI Description */}
      <div id="description">
        <h6>What is BMI?</h6>
        <p>
          Body Mass Index (BMI) is a measure of body fat based on height and weight. It's a simple calculation that can help you assess your weight status and understand your risk for certain health conditions.
        </p>
        <h6>BMI Categories:</h6>
        <ul>
          <li><strong>Underweight:</strong> Less than 18.5</li>
          <li><strong>Normal weight:</strong> 18.5 to 24.9</li>
          <li><strong>Overweight:</strong> 25 to 29.9</li>
          <li><strong>Obesity:</strong> 30 or higher</li>
        </ul>
        <p>It's important to note that BMI is just one factor to consider when assessing your overall health. Consult with a healthcare professional for personalized advice.</p>
      </div>

      {/* Health Coach Button */}
      <div id="health-coach">
        <button onClick={() => window.location.href='https://www.balm.ai/user/signin'}>Speak to a Health Coach</button>
      </div>
    </div>
  );
}

export default BmiCalculator;
